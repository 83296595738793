import React from "react";
import { navigateTo } from "gatsby-link";
import TextField from "@material-ui/core/TextField";
import Button from "../CustomButtons/Button.jsx";
// import styled from "@emotion/styled";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Create from "@material-ui/icons/Create";

import ContactFormStyle from "assets/jss/material-kit-react/views/componentsSections/contactformstyle.jsx";

// export const ButtonText = styled.div`
// word-spacing: 0.25rem;
// letter-spacing: 0.15rem; 
//   }
// `;


// function encode(data) {
//   return Object.keys(data)
//     .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//     .join("&");
// }

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // handleChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   const form = e.target;
  //   fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({
  //       "form-name": form.getAttribute("name"),
  //       ...this.state,
  //     }),
  //   })
  //     .then(() => navigateTo(form.getAttribute("action")))
  //     .catch((error) => alert(error));
  // };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
       <form name="Colliery Business Park Contact Form" method="post" data-netlify="true">
                    {/* <input type="hidden" name="form-name" value="Colliery BP Contact Form" /> */}
                     {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                     <input type="hidden" name="form-name" value="Colliery Business Park Contact Form" />
          {/* <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p> */}
                    <CardBody>
                    {/* <TextField id="standard-basic" label="Name" 
                    
                    endAdornment={
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor}/>
                      </InputAdornment>
                    }
                    /> */}

{/* <FormControl>
        <InputLabel htmlFor="name">Name</InputLabel>
        <Input
          id="name"
          endAdornment={
            <InputAdornment position="end">
              <People className={classes.inputIconsColor}/>
            </InputAdornment>
          }
        />
      </FormControl> */}
      <FormControl>
        <InputLabel htmlFor="name">Name</InputLabel>
        <Input
          id="name"
          autoComplete="name"
                name="name"
          endAdornment={
            <InputAdornment position="end">
              <People className={classes.inputIconsColor}/>
            </InputAdornment>
          }
        />
      </FormControl>
      <div className={classes.marginform}></div>
      <FormControl>
        <InputLabel htmlFor="email">Email</InputLabel>
        <Input
          id="email"
          autoComplete="email"
                name="email"
          endAdornment={
            <InputAdornment position="end">
              <Email className={classes.inputIconsColor}/>
            </InputAdornment>
          }
        />
      </FormControl>
      <div className={classes.marginform}></div>
      <FormControl>
        <InputLabel htmlFor="message">Message</InputLabel>
        <Input
                  id="message"
                  autoComplete="message"
                        name="message"
          endAdornment={
            <InputAdornment position="end">
              <Create className={classes.inputIconsColorTwo}/>
            </InputAdornment>
          }
        />
      </FormControl>
        {/* <Input
          
          id="standard-basic"
          label="Standard" 
          endAdornment={
            <InputAdornment position="end">
              <People className={classes.inputIconsColor}/>
            </InputAdornment>
          }
        /> */}
        </CardBody>
        <CardFooter className={classes.cardFooter}>
                      <Button type="submit" simple color="primary" size="lg">
                        SUBMIT
                      </Button>
        </CardFooter>
      </form>           
      </div>
    );
  }
}

export default withStyles(ContactFormStyle)(ContactForm);

